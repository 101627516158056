import React from 'react';
import clsx from 'clsx';
import * as styles from './Breadcrumb.module.scss';
import { Link } from 'gatsby';

export default function Breadcrumb({list, className = null}) {
  return (
    <section className={clsx(styles.breadcrumb, className)}>
      <div className={clsx('section-wrapper', styles.sectionWrapper)}>
        <div className={styles.wrapper}>
          {
            list && list.map((item) => {
              if(item.link_url && item.link_url.link_type !== 'Any') {
                let url = '/';
                let target = '_self';

                if(item.link_url.link_type === 'Document'){
                  if(item.link_url.slug !== 'homepage'){
                    url = item.link_url.slug;
                  }
                } else {
                  url = item.link_url.url;
                  target = item.link_url.target;
                }

                return(
                  <span key={item.link_text.text} className={styles.page}>
                    <Link to={url} target={target}>{item.link_text.text}</Link>
                  </span>
                )

              } else {
                return(
                  <span key={item.link_text.text} className={styles.page}>{item.link_text.text}</span>
                )
              }
            })
          }
        </div>
      </div>
    </section>
  );
}