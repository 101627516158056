import clsx from 'clsx';
import React from 'react';
import * as styles from './Button.module.scss';
import { Link } from 'gatsby';

export default function Button({className, type, children, url, target}) {
  return (
    <>
      {
        type === 'submit' ?
          <button className={clsx(styles.button, className)} type='submit'>
            <span className={styles.text}>{children}</span>
          </button>
        :
          target === '_blank' ?
            <a href={url} className={clsx(styles.button, className)} target={target}>
              <span className={styles.text}>{children}</span>
            </a>
          :
            <Link to={url || '#'} className={clsx(styles.button, className)}>
              <span className={styles.text}>{children}</span>
            </Link>
      }
    </>
  );
}