export function getURL(cta) {
  let url = '/';
  let target = '_self';

  if(cta.link_type === 'Document'){
    if(cta.slug !== 'homepage'){
      url = `/${cta.slug}`;
    }

  } else {
    url = cta.url;
    target = cta.target;
  }

  return {
    url,
    target
  }
}