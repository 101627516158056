import Button from '../Button/Button';
import clsx from 'clsx';
import parse from 'html-react-parser';
import React from 'react';
import * as styles from './Callout.module.scss';
import {getURL} from '../../scripts/utils';
import Title from "../Title/Title";

export default function Callout({text, cta, image, headline, subhead, className}) {

  function Cta({cta}) {
    if (cta) {
      const link = getURL(cta.url);
      return (
        <Button className={styles.cta} url={link.url} target={link.target}> {cta.text} </Button>
      );
    }
  }

  return (
    <section className={clsx('section-wrapper', className)}>
      {headline && (
        <div className={clsx(styles.content_head)}>
          <Title className={clsx(styles.headline)}>{parse(headline)}</Title>
          <Title className={clsx(styles.subhead)}>{parse(subhead)}</Title>
        </div>
      )}
      <div className={styles.wrapper}>
        {
          image &&
          <div className={styles.image}>
            <img src={image.url} alt={image.alt}/>
          </div>
        }
        <div className={clsx(styles.content, (text === '' || text === null || text === '<p></p>') && styles.noText)}>
          {
            text &&
            <div className={styles.text}> {parse(text)}</div>
          }
          <Cta cta={cta}/>
        </div>
      </div>
    </section>
  );
}