import React from 'react'
import * as styles from './Card.module.scss'
import clsx from 'clsx';
import Button from '../../components/Button/Button'

const Card = ({ card, className}) => {

if(card.title != null) {    
  return (
    <div className={clsx(styles.card, className && className)}>
      {card.image && <img className={styles.image} src={card.image.url} alt={card.image.alt}/>}
      <div className={styles.container}>
        <p className={styles.date}>{card.date.text}</p>
        <p className={styles.title}>{card.title.text}</p>
        {card.name && <p className={styles.name}>{card.name.text}</p>}
        <Button className={clsx(styles.cta, 'card-btn')} url={card.link.url} target={card.link.target}>View Article</Button>
      </div>
    </div>
      )
    } else {
  return (        
    <div className={clsx(styles.card, className && className)}>
      {card.data.image && <img className={styles.image} src={card.data.image.url} alt={card.data.image.alt}/>}
      <div className={styles.container}>
        <p className={styles.date}>{card.data.date.text}</p>
        <p className={styles.title}>{card.data.article_title.text}</p>
        {card.data.name && <p className={styles.name}>{card.data.name.text}</p>}
        <Button className={clsx(styles.cta, 'card-btn')} url={card.uid} target="_self">View Article</Button>
      </div>
    </div>      
  )
    }
};

export default Card;