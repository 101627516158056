
import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Callout from '../components/Callout/Callout';
import Cards from '../components/Cards/Cards';
import Hero from '../components/Hero/Hero';
import TextSection from '../components/TextSection/TextSection';

export default function InvestorsPage({data}) {

  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-news')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb  className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        <TextSection text={[data.page.data.news_headline.html, data.page.data.news_subhead.html]} className="black-title"/>
        <Cards 
          cards={data.page.data.news}
          className='bg-gray-light top-spacing'
        />
        <TextSection text={[data.page.data.pr_headline.html, data.page.data.pr_subhead.html]} className="black-title"/>
        <Cards 
          cards={data.press_releases.nodes}
          className='bg-gray-light top-spacing'
        />
        <Callout
          text={ data.page.data.callout_text.html }
          cta={{
            text: data.page.data.callout_cta_text.text,
            url: data.page.data.callout_cta_url
          }}
          image={ data.page.data.callout_image }
        />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const NewsQuery = graphql`
{
  page: prismicNews {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
      news_headline {
        html
      }
      news_subhead {
        html
      }
      news {
        date {
          text
        }
        title {
          text
        }
        link {
          url
          target
        }
      }
      pr_headline {
        html
      }
      pr_subhead {
        html
      }
      callout_text {
        html
      }
      callout_image {
        url
        alt
      }
      callout_cta_text {
        text
      }
      callout_cta_url {
        target
        slug
        link_type
        url
      }
    }
  }
  press_releases: allPrismicArticle(sort: {data: {sort_date: DESC}}, limit: 100) {
    nodes {
      data {
        article_title {
          text
        }
        date {
          text
        }
      }
      uid
    }
  }
}
`
