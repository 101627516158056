import React from 'react';
import parse from 'html-react-parser';
import * as styles from './Cards.module.scss';
import clsx from 'clsx';
import Card from './Card'

const Cards = ({ headline, cards, className}) => {

  return (
    <section className={clsx(className && className, styles.cards)}>
      <div className={clsx('section-wrapper', styles.wrapper)}>
        {headline && <div className={styles.headline}>
          { parse(headline) }
        </div>}
        <div className={styles.list}>
          {cards.map((card, i) => <Card key={i} card={card} />)}
        </div>
      </div>
    </section>
  )
};

export default Cards;